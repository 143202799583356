/**
 * This file is used to define the environment variables for the application.
 * To have a high level of flexibility, all environment variables have a default value,
 * which can be overridden through the window object. *
 */
declare global {
  interface Window {
    SHIFTBASE_VERSION: string;
    SHIFTBASE_CONFIG: object;
  }
}

/**
 * A simple function that retrieves an "environment" variable from the window object
 * or returns a fallback value if the environment variable is not set.
 *
 * @param variable Name of the environment variable to retrieve
 * @param fallback Fallback value to be returned when the environment variable is not set
 */
const env = <T>(variable: string, fallback: T): T => {
  if (window.SHIFTBASE_CONFIG && typeof window.SHIFTBASE_CONFIG[variable] !== 'undefined') {
    return window.SHIFTBASE_CONFIG[variable] as T;
  }

  if (typeof process.env[variable] !== 'undefined') {
    return process.env[variable] as T;
  }

  return fallback;
};

/**
 * The configuration object
 *
 * This object holds the full configuration for the application,
 * it is in line with the environment configuration of the Angular application.
 * But made more flexible by allowing the configuration to be overridden by the window object.
 * This window object in the end is controlled through the SSI module of NGINX;
 *
 * @see https://angular.io/guide/build#configuring-application-environments
 * @see https://nginx.org/en/docs/http/ngx_http_ssi_module.html
 */
export const config = {
  /** Environment the application is running in */
  env: env('APPLICATION_ENVIRONMENT', 'dev'),

  /** Instance of the application, used to differentiate different deployments within the same environment */
  instance: env('APPLICATION_INSTANCE', 'development'),

  /** Current application version */
  version: window.SHIFTBASE_VERSION,

  /** Domain of the Shiftbase helpcenter */
  knowledgeBaseUrl: env('SHIFTBASE_HELPCENTER_DOMAIN', 'https://help.shiftbase.com'),

  /** IP address of the enduser */
  ipAddress: undefined,

  /** Google Maps API key */
  mapsKey: env('GOOGLE_MAPS_API_KEY', 'AIzaSyA44Sha2DAPoZwAOHjJ-YCDmXS9VatK9J4'),

  /* The phrase project id used for in context translations */
  phraseProjectId: env('PHRASE_PROJECT_ID', '1040053358e773ee1e130c00dec9dfdf'),

  /* The phrase account id used for in context translations */
  phraseAccountId: env('PHRASE_ACCOUNT_ID', 'cbbab6f3'),

  /** source for the HubSpot script  */
  hubSpotScript: env('HUBSPOT_SCRIPT_SRC', '//js.hs-scripts.com/21022444.js'),

  /** ID of the hubspot custom behavioral event for signups */
  hubSpotCustomBehavioralEvent: env('HUBSPOT_CUSTOM_BEHAVIORAL_EVENT', 'pe21022444_sb_signup'),

  /** Chargebee site to use for billing */
  chargeBeeSite: env('CHARGEBEE_SITE', 'shiftbasebv-test'),

  /** Enable / disable Rudderstack (event tracking) */
  rudderstackEnabled: env('RUDDERSTACK_ENABLED', true),

  /** Write key to use when pushing events to Rudderstack */
  rudderWriteKey: env('RUDDERSTACK_WRITE_KEY', '2UKklP3Nk3UBwM19LJJh5y3AMrk'),

  /** Data plane URL to use when pushing events to Rudderstack */
  rudderDataPlaneUrl: env('RUDDERSTACK_DATA_PLANE_URL', 'https://rudderstack.c03.acc.shiftbase.com'),

  /** The recaptcha site key to use with signups */
  recaptchaSiteKey: env('RECAPTCHA_SITE_KEY', '6Lc5NKwaAAAAAKPw4u4uGCL8kWwbmvk60PGczl7k'),

  /** The refiner project id to use for user surveys */
  refinerProjectId: env('REFINER_PROJECT_ID', 'd933f3c0-baa6-11ee-854b-57cb940f2613'),

  /** The sentry DSN for error tracking */
  sentryDsn: env('SENTRY_DSN', 'https://9204c1250b494a2491ec00b8db4cddc2@sentry.io/261923'),

  /** The unleash URL to use */
  unleashUrl: env('UNLEASH_URL', 'https://unleash-edge.shiftbase.com'),

  /** The unleash client key to use */
  unleashClientKey: env('UNLEASH_CLIENT_KEY', '*:development.2ae3de79f928f812897c35b7f69227ca22868577cc8b7be1cd95e6a2'),

  /** Prosperstack client key */
  prosperstackClientKey: env('PROSPERSTACK_CLIENT_KEY', 'acct_zvjLzUQaXwzq5vqv7btA7FJv'),
};
